function toggleModal () {
  $("button.menu").toggleClass('tw-active');
  $(".modal").toggleClass('tw-hidden');
  $(".modal").toggleClass('tw-opacity-0');
  $("div.logo").toggleClass('tw-hidden');
  $("body").toggleClass('modal-active');
}

$(function() {
    $("button.menu").on('click', function() {
        toggleModal();
    });

    $(document).on('keyup',function(evt) {
        if (evt.key == "Escape" && $("body").hasClass("modal-active")) {
            toggleModal();
        }
    });
})