$(function() {
    $("#mailchimp").on('submit', function(e) {
        e.preventDefault();
        var b = $(this).find('button');
        b.prop("disabled",true);
        b.data("old", b.html());
        b.html("Wird gesendet...");
        $.post({
            url: '/mailchimp/send',
            data: $(this).serialize(),
            success: (r) => {
                if (r.success) {
                    var suc=$(this).find('div.success');
                    suc.text('Vielen Dank für Ihr Interesse an unserem Newsletter.');
                    suc.css('display','block');
                    var b = $(this).find('button');
                    b.css('display','none');
                    var b = $(this).find('input');
                    b.css('display','none');
                } else {
                    var msg = 'Beim Verarbeiten des Formulars ist ein Fehler aufgetreten!'
                    if (r.msg=='Mailchimp error: Member Exists')
                    {
                        msg = 'Ihre E-Mail-Adresse ist bereits für den Newsletter registriert.'
                    }
                    var err=$(this).find('div.error');
                    err.text(msg);
                    err.css('display','block');
                    var b = $(this).find('button');
                    b.prop("disabled",false);
                    b.html(b.data("old"));
                }
            },
            error: (e) => {
                var msg = 'Beim Verarbeiten des Formulars ist ein Fehler aufgetreten!'
                var err=$("#mailchimp").find('div.error');
                err.text(msg);
                err.css('display','block');
                var b = $("#mailchimp").find('button');
                b.prop("disabled",false);
                b.html(b.data("old"));
            }
        });
        
    });
})