$('#contact_form').on('submit', function(e) {
    // Prevent the form from actually submitting
    e.preventDefault();

    var b = $(this).find('button');
    b.prop("disabled",true);
    b.data("old", b.html());
    b.html("Wird gesendet...");

    $(this).find('div.error').fadeOut();

    // Send it to the server
    $.post({
        url: '/',
        dataType: 'json',
        data: $(this).serialize(),
        success: function(response) {
            $('#contact_form').find('div.success').text(response.message).fadeIn();
            $('#contact_form').find('div.form').fadeOut();
        },
        error: function(jqXHR) {
          // The response body will be an object containing the following keys:
          // - `message` – A high level message for the response
          // - `submission` – An object containing data from the attempted submission
          // - `errors` – An object containing validation errors from the submission, indexed by attribute name

          $('#contact_form').find('input').removeClass('tw-border-red');
          $('#contact_form').find('ul').remove();

          $('#contact_form').find('div.error').text(jqXHR.responseJSON.message).fadeIn();
          $.each(jqXHR.responseJSON.errors, function (name, value) {
            var el = $('#contact_form').find('input[name="'+name+'"]');
            if (name=='message') {
                el = $('#contact_form').find('textarea');
            }
            var a = el.addClass('tw-border-red').after('<ul class="tw-text-red tw-mt-2 tw-mb-4"></ul>');
            $.each(value, function (i,v) {
                a.next().append('<li>'+v+'</li>');
            });
          });

          var b =$('#contact_form').find('button');
          b.prop("disabled",false);
          b.html(b.data("old"));
        }
    });
});